import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Parse from 'parse';
import { AlertService } from '../services/alert.service';
import { Report } from '../models/report';
import { ChartData } from '../models/chart-data';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe, DecimalPipe]
})
export class DashboardComponent implements OnInit {

  isLoading = true;
  report: Report = {};
  todaysDate = Date();
  lastFlightDateFromNow: string;
  chartData: ChartData = {};

  constructor(private alertService: AlertService, 
              private datePipe: DatePipe, 
              private decimalPipe: DecimalPipe,
              private headerService: HeaderService) {
    headerService.setHeader(this.datePipe.transform(this.todaysDate, 'MMM dd, yyyy'));
  }

  ngOnInit() {
    Parse.Cloud.run('getReport', {timezone: moment().utcOffset()})
    .then((result) => {
      this.report = JSON.parse(result);
      this.lastFlightDateFromNow = moment(this.report.lastFlightDate).fromNow();
      this.isLoading = false;

      this.chartData.colors = [
        {
          backgroundColor: 'RGBA(213, 232, 255, 1.00)',
          borderColor: 'RGBA(65, 139, 202, 1.00)',
          pointBackgroundColor: 'RGBA(65, 139, 202, 1.00)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'RGBA(65, 139, 202, 1.00)'
        }
      ];

      this.chartData.labels =  [
        this.datePipe.transform(moment().subtract(6, 'days').toDate(), 'EEEE') as string,
        this.datePipe.transform(moment().subtract(5, 'days').toDate(), 'EEEE') as string,
        this.datePipe.transform(moment().subtract(4, 'days').toDate(), 'EEEE') as string,
        this.datePipe.transform(moment().subtract(3, 'days').toDate(), 'EEEE') as string,
        this.datePipe.transform(moment().subtract(2, 'days').toDate(), 'EEEE') as string,
        this.datePipe.transform(moment().subtract(1, 'days').toDate(), 'EEEE') as string,
        this.datePipe.transform(moment().toDate(), 'EEEE') as string
      ];

      this.chartData.data = [
          {data: [this.decimalPipe.transform(this.report.last7DaysDict.day7, '1.1-1'),
                  this.decimalPipe.transform(this.report.last7DaysDict.day6, '1.1-1'),
                  this.decimalPipe.transform(this.report.last7DaysDict.day5, '1.1-1'),
                  this.decimalPipe.transform(this.report.last7DaysDict.day4, '1.1-1'),
                  this.decimalPipe.transform(this.report.last7DaysDict.day3, '1.1-1'),
                  this.decimalPipe.transform(this.report.last7DaysDict.day2, '1.1-1'),
                  this.decimalPipe.transform(this.report.last7DaysDict.day1, '1.1-1')], label: 'Flight Time'},
      ];
    })
    .catch((err) => {
      this.isLoading = false;
      this.alertService.error((err as any).message);
    });
  }

}
