import { Component, OnInit } from '@angular/core';
import { NewUser } from '../models/new-user';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';
import * as Parse from 'parse';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  newUser: NewUser = new NewUser();
  loading = false;

  constructor(private router: Router, private alertService: AlertService) { }

  ngOnInit() {
  }

  signUp() {
    if (this.newUser.password !== this.newUser.passwordAgain) {
      this.alertService.error('Passwords don\'t match');
      return;
    }

    this.loading = true;
    const parseUser = new Parse.User();
    parseUser.setUsername(this.newUser.username.toLocaleLowerCase());
    parseUser.setEmail(this.newUser.username.toLocaleLowerCase());
    parseUser.setPassword(this.newUser.password);

    parseUser.signUp(null, null).then(() => {
      this.loading = false;
      this.alertService.success('Welcome! You have successfully signed up.');
      this.router.navigate(['/dashboard']);
    }).catch((error) => {
      this.loading = false;
      this.alertService.error((error as any).message);
    });
  }
}
