import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { LogbookComponent } from './logbook/logbook.component';
import { LogEntryComponent } from './log-entry/log-entry.component';
import { CustomFieldsEditorComponent } from './custom-fields-editor/custom-fields-editor.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupComponent } from './signup/signup.component';
import { ReportComponent } from './report/report.component';
import { ImportLogbookComponent } from './import-logbook/import-logbook.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'logbook', component: LogbookComponent, canActivate: [AuthGuard]},
  { path: 'logentry', component: LogEntryComponent, canActivate: [AuthGuard]},
  { path: 'customfields', component: CustomFieldsEditorComponent, canActivate: [AuthGuard]},
  { path: 'import-logbook', component: ImportLogbookComponent, canActivate: [AuthGuard]},
  { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  { path: 'reports', component: ReportComponent, canActivate: [AuthGuard]},
  { path: 'forgotpassword', component: ForgotPasswordComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
