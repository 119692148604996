import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import * as Parse from 'parse';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-custom-fields-editor',
  templateUrl: './custom-fields-editor.component.html',
  styleUrls: ['./custom-fields-editor.component.css']
})
export class CustomFieldsEditorComponent implements OnInit {

  fields: Parse.Object[];
  loading = true;
  newFieldTitle: string;

  constructor(private alertService: AlertService, private headerService: HeaderService) {
    headerService.setHeader('Custom Fields');
  }

  ngOnInit() {
    this.getCustomFields();
  }

  getCustomFields() {
    const fields = Parse.Object.extend('CustomField');
    const query = new Parse.Query(fields);
    query.find().then(f => {
      this.fields = f;
      this.loading = false;
    })
    .catch(error => {
      this.alertService.error('An error occured retrieving this log entry: ' + error);
    });
  }

  delete(customField: Parse.Object) {
    if (confirm('Are you sure you want to delete the ' + customField.get('fieldDisplayName') + ' field?')) {
      this.loading = true;
      customField.destroy().then(cf => {
        this.alertService.success('Custom Field Deleted.');
        this.getCustomFields();
      }).catch(error => {
        this.loading = false;
        this.alertService.error('Error deleting Custom Field.');
      });
    }
  }

  add() {
    if (this.newFieldTitle == null || this.newFieldTitle === '') { return; }

    this.loading = true;
    const CustomField = Parse.Object.extend('CustomField');
    const customField = new CustomField();

    customField.set('fieldDisplayName', this.newFieldTitle);
    customField.set('fieldName', this.newFieldTitle.replace(/\s/g, ''));
    customField.setACL(new Parse.ACL(Parse.User.current()));
    customField.save().then((cf) => {
      this.loading = false;
      this.alertService.success('Custom Field Added.');
      this.newFieldTitle = '';
      this.getCustomFields();
    }).catch(error => {
      this.loading = false;
      this.alertService.error('Error adding Custom Field: ' + error.message);
    });
  }

}
