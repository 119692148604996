import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from '../services/alert.service';
import * as Parse from 'parse';
import { Router } from '@angular/router';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  existingPassword: string;
  newPassword: string;
  repeatPassword: string;
  changePasswordForm: FormGroup;
  loading = false;

  constructor(private alertService: AlertService, private router: Router, private headerService: HeaderService) {
    headerService.setHeader('Change Password');
  }

  ngOnInit() {
  }

  changePassword() {
    const currentUser = Parse.User.current();
    if (this.newPassword === this.repeatPassword) {
      currentUser.set('password', this.newPassword);
      currentUser.save().then(() => {
        this.alertService.success('Password Changed.');
        this.router.navigate(['/dashboard']);
      }).catch(error => {
        this.alertService.success('Password Change failed.');
      });
    } else {
      this.alertService.error('Passwords don\'t match');
    }
  }
}
