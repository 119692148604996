import { Component, OnInit } from '@angular/core';
import { LoginCredentials } from '../models/login-credentials';
import { NewUser } from '../models/new-user';
import * as Parse from 'parse';
import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { LogbookComponent } from '../logbook/logbook.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: LoginCredentials = new LoginCredentials();
  loading = false;

  constructor(private router: Router, private alertService: AlertService) { }

  ngOnInit() {
  }

  logIntoServer() {
    this.loading = true;
    //TODO: Find a better way to handle this searchQuery saving...
    LogbookComponent.searchQuery = '';
    
    Parse.User.logIn(this.login.username.toLocaleLowerCase(), this.login.password)
              .then((res) => {
                this.loading = false;
                this.router.navigate(['/dashboard']);
              })
              .catch((err) => {
                this.loading = false;
                this.alertService.error((err as any).message);
              });
  }
}
