export class ReportDisplayName {
    static fieldToDisplayName = {
        'totalLogbookEntries': 'Total Logbook Entries',
        'totalFlightTime': 'Total Flight Time',
        'last12Months': 'Last 12 Months',
        'last90Days': 'Last 90 Days',
        'last6Month': 'Last 6 Months',
        'last30Days': 'Last 30 Days',
        'last7Days': 'Last 7 Days',
        'previous7DaysBeforeLast': '7-14 days ago',
        'instrumentApproaches': 'Instrument Approaches Last 6 Months',
        'holds': 'Holds Last 6 Months',
        'dayLandings': 'Day Landings Last 90 Days',
        'nightLandings': 'Night Landings Last 90 Days',
        'dayLandingsCAA': 'Day Landings Last 6 Months',
        'nightLandingsCAA': 'Night Landings Last 6 Months',
        'instrumentTimeCAA': 'Instrument Time Last 6 Months',
        'lastFlightTime': 'Last Flight Time',
        'lastFlightDate': 'Last Flight Date',
        'totalNight': 'Total Night',
        'totalNight90Days': 'Total Night last 90 Days',
        'totalNight12Months': 'Total Night last 12 Months',
        'totalInstrument': 'Total Instrument',
        'totalInstrument90Days': 'Total Instrument last 90 Days',
        'totalInstrument12Months': 'Total Instrument last 12 Months',
        'totalPIC': 'Total PIC',
        'totalPIC90Days': 'Total PIC last 90 days',
        'totalPIC12Months': 'Total PIC last 12 Months',
        'totalCrossCountry': 'Total Cross Country',
        'totalCrossCountry90Days': 'Total Cross Country last 90 Days',
        'totalCrossCountry12Months': 'Total Cross Country last 12 Months',
        'totalSingleEngine': 'Total Single Engine',
        'totalSingleEngine90Days': 'Total Single Engine last 90 Days',
        'totalSingleEngine12Months': 'Total Single Engine last 12 Months',
        'totalMultiEngine': 'Total Multi-Engine',
        'totalMultiEngine90Days': 'Total Multi-Engine Last 90 Days',
        'totalMultiEngine12Months': 'Total Mutli-Engine Last 12 Months',   
    }
}