import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private successMessageSubject = new BehaviorSubject('');
  private errorMessageSubject = new BehaviorSubject('');

  successMessage = this.successMessageSubject.asObservable();
  errorMessage = this.errorMessageSubject.asObservable();

  constructor() { }

  success(message: string) {
    this.successMessageSubject.next(message);
  }

  error(message: string) {
    this.errorMessageSubject.next(message);
  }
}
