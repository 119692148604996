import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import * as Parse from 'parse';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  emailAddress: string;
  loading = false;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
  }

  resetPassword() {
    this.loading = true;
    Parse.User.requestPasswordReset(this.emailAddress.toLocaleLowerCase()).then(() => {
      this.loading = false;
      this.alertService.success('Reset Password e-mail has been sent.');
    }).catch((error) => {
      this.loading = false;
      this.alertService.error((error as any).message);
    });
  }

}
