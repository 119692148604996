import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as Parse from 'parse';
import { DecimalPipe } from '@angular/common';
import { Report } from '../models/report';
import { AlertService } from '../services/alert.service';
import { ReportDisplayName } from './report.fielddisplaynames';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [DecimalPipe]
})
export class ReportComponent implements OnInit {

  isLoading = true;
  report: Report = {};
  timeInType = {};
  customFieldsReport = {};
  reportKeys: string[] = [];
  reportDisplayNames = ReportDisplayName.fieldToDisplayName
  customFields = {};
  customFieldsDaysAgo = 7;

  constructor(private alertService: AlertService,
    private headerService: HeaderService) {
      headerService.setHeader("Reports");
  }

  ngOnInit() {
    this.loadLogbookReport();
  }

  tabSelected(tab) {
    switch(tab.nextId) {
      case "logbook": {
        this.loadLogbookReport();
        break;
      }
      case "timeInType": {
        this.loadTimeInTypeReport();
        break;
      }
      case "customFields": {
        this.loadCustomFieldsReport();
        break;
      }
    }
  }

  loadLogbookReport() {
    this.isLoading = true;
    
    Parse.Cloud.run('getReport', {timezone: moment().utcOffset()})
    .then((result) => {
      this.report = JSON.parse(result);
      delete this.report["last7DaysDict"];
      this.reportKeys = Object.keys(this.report);
      this.isLoading = false;
    })
    .catch((err) => {
      this.isLoading = false;
      this.alertService.error((err as any).message);
    });
  }

  loadTimeInTypeReport() {
    this.isLoading = true;

    Parse.Cloud.run('getTimeInTypeReport', {})
    .then((result) => {
      this.timeInType = JSON.parse(result);
      this.reportKeys = Object.keys(this.timeInType);
      this.isLoading = false;
    })
    .catch((err) => {
      this.isLoading = false;
      this.alertService.error((err as any).message);
    });
  }

  loadCustomFieldsReport() {
    this.isLoading = true;

    const fields = Parse.Object.extend('CustomField');
    const query = new Parse.Query(fields);
    query.find().then(f => {

      f.forEach (field => {
        this.customFields[field.get('fieldName')] = field.get('fieldDisplayName');
      });

      Parse.Cloud.run('getReportForCustomFields', {timezone: moment().utcOffset(), daysago: this.customFieldsDaysAgo})
      .then((result) => {
        this.customFieldsReport = JSON.parse(result);
        this.reportKeys = Object.keys(this.customFieldsReport);
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        this.alertService.error((err as any).message);
      });
    })
    .catch(error => {
      this.isLoading = false;
      this.alertService.error((error as any).message);
    });
  }
}
