import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.css']
})
export class LogbookComponent implements OnInit {

  flights = [];
  page = 1;
  loading = true;
  itemsPerPage = 20;
  maxSize = 10;
  numberOfFlights = 0;
  static searchQuery = '';
  isFiltering = false;

  constructor(private alertService: AlertService, 
              private router: Router,
              private headerService: HeaderService) {
    headerService.setHeader("Logbook");
  }

  ngOnInit() {
    this.isFiltering = (LogbookComponent.searchQuery != null && LogbookComponent.searchQuery != '') 
    this.countFlights();
  }

  countFlights() {
    const query = this.createQuery();
    
    query.count().then((count) => {
      this.headerService.setRightHeader('(' + count + ' flights)');
      this.numberOfFlights = count;
      this.pageChanged(1);
    })
    .catch((error) => {
      this.loading = false;
      this.alertService.error((error as any).message);
    });
  }

  pageChanged(page) {
    this.page = page;
    
    const query = this.createQuery();
    query.addDescending('flightDate');
    query.limit(this.itemsPerPage);
    query.skip((this.page - 1) * this.itemsPerPage);

    query.find().then((flights) => {
      this.loading = false;
      this.flights = flights;
    })
    .catch((error) => {
      this.loading = false;
      this.alertService.error((error as any).message);
    });
  }

  showEntry(flight: Parse.Object) {
    this.router.navigate(['logentry'], {queryParams: {flightId: flight.id}});
  }

  getPhotoUrlCount(flight) {
    const photoUrls = flight.get('photoUrls');

    return photoUrls == null ? 0 : photoUrls.length;
  }

  delete(logentry: Parse.Object) {
    if (confirm('Are you sure you want to delete the ' + logentry.get('flightDate') + ' log entry?')) {
      this.loading = true;
      logentry.destroy().then(cf => {
        this.loading = false;
        this.alertService.success('Logbook Entry Deleted.');
        this.pageChanged(this.page);
      }).catch(error => {
        this.loading = false;
        this.alertService.error('Error deleting Logbook Entry.');
      });
    }
  }

  search() {
    this.isFiltering = true;
    this.countFlights();
  }

  resetSearch() {
    this.isFiltering = false;
    LogbookComponent.searchQuery = '';
    this.countFlights();
  }

  getSearchQuery() {
    return LogbookComponent.searchQuery;
  }

  setSearchQuery(query) {
    LogbookComponent.searchQuery = query;
  }

  private createQuery() : Parse.Query {
    const flight = Parse.Object.extend('Flight');
    const query = new Parse.Query(flight);

    const regex = new RegExp(LogbookComponent.searchQuery, 'i')

    if(LogbookComponent.searchQuery != null && LogbookComponent.searchQuery != '') {
      return Parse.Query.or(
        (new Parse.Query(flight)).matches('remarks', regex, 'i'),
        (new Parse.Query(flight)).matches('makeModel', regex, 'i'),
        (new Parse.Query(flight)).matches('aircraftIdent', regex, 'i'),
        (new Parse.Query(flight)).matches('fromAirport', regex, 'i'),
        (new Parse.Query(flight)).matches('toAirport', regex, 'i'));
    } else {
      return query;
    }
  }
}
