import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { fromEventPattern } from 'rxjs';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlertComponent } from './alert/alert.component';
import { AuthGuard } from './auth.guard';
import { ChartsModule } from 'ng2-charts';
import { LogbookComponent } from './logbook/logbook.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogEntryComponent } from './log-entry/log-entry.component';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { CustomFieldsEditorComponent } from './custom-fields-editor/custom-fields-editor.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LightboxModule } from 'ngx-lightbox';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupComponent } from './signup/signup.component';
import { ReportComponent } from './report/report.component';
import { LogbookValueFormatterPipe } from './pipes/logbook-value-formatter.pipe';
import { ImportLogbookComponent } from './import-logbook/import-logbook.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeftNavComponent,
    DashboardComponent,
    AlertComponent,
    LogbookComponent,
    LogEntryComponent,
    CustomFieldsEditorComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    SignupComponent,
    ReportComponent,
    LogbookValueFormatterPipe,
    ImportLogbookComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    NgbModule,
    AngularDateTimePickerModule,
    FontAwesomeModule,
    LightboxModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    library.add(fas);
  }

}
