import { Injectable } from '@angular/core';
import * as Parse from 'parse';
const parse = require('parse');

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor() { }

  isLoggedIn() {
    return (Parse.User.current() != null);
  }
}
