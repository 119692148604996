import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import * as Parse from 'parse';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { Lightbox } from 'ngx-lightbox';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-log-entry',
  templateUrl: './log-entry.component.html',
  styleUrls: ['./log-entry.component.css']
})
export class LogEntryComponent implements OnInit {

  flightId = '';
  flight: Parse.Object;
  loading = true;
  customFieldValues = {};
  customFields: Parse.Object[];

  date = new Date();

  datePickerSettings = {
    bigBanner: true,
    timePicker: true,
    format: 'MMMM dd yyyy hh:mm a',
    defaultOpen: false,
  };

  constructor(private router: Router,
    private alertService: AlertService,
    private _lightbox: Lightbox,
    private headerService: HeaderService) {
    router.routerState.root.queryParams.subscribe(params => {
      if (this.flightId === params['flightId']) { return; }
      this.flightId = params['flightId'];

      const flight = Parse.Object.extend('Flight');

      if (this.flightId != null) {
        const query = new Parse.Query(flight);
        query.get(this.flightId).then(f => {
          this.flight = f;
          if (f.get('customFields') != null) {
            this.customFieldValues = f.get('customFields');
          }
          this.getCustomFields();
        })
          .catch(error => {
            alertService.error('An error occured retrieving this log entry: ' + error);
          });
      } else {
        this.flight = new flight();
        this.flight.setACL(new Parse.ACL(Parse.User.current()));
        this.flight.set('user', Parse.User.current());
        this.flight.set('flightDate', new Date());
        this.getCustomFields();
      }
    });

    headerService.setHeader('Log Entry');
  }

  ngOnInit() {
  }

  save() {
    const flightJson = this.flight.toJSON();

    for (const k in flightJson) {
      if (flightJson[k] == null) {
        this.flight.unset(k);
      }
    }

    this.flight.set('customFields', this.customFieldValues);
    this.flight.save().then(f => {
      this.alertService.success('Log Entry Saved');
      this.router.navigate(['logbook']);
    });
  }

  setFromTotalTime(field) {
    this.flight.set(field, this.flight.get('totalFlightTime'));
  }

  setCustomFieldFromTotalTime(field) {
    this.customFieldValues[field] = this.flight.get('totalFlightTime');
  }

  getCustomFields() {
    const fields = Parse.Object.extend('CustomField');
    const query = new Parse.Query(fields);
    query.find().then(f => {
      this.customFields = f;
      this.loading = false;
    })
      .catch(error => {
        this.loading = false;
        this.alertService.error('An error occured retrieving this log entry: ' + error);
      });
  }

  openPhoto(photoUrl) {
    const album = {
      src: photoUrl,
      caption: '',
      thumb: photoUrl
    };
    this._lightbox.open([album], 0);
  }

  setDateFromInputBox(dateStr: string) {
    this.flight.set('flightDate', new Date(dateStr));
  }
}
