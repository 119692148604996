import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AlertService } from './services/alert.service';
import { HeaderService } from './services/header.service';
const parse = require('parse');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'PilotLog2-Web';
  isSideBarActive = true;
  headerText = "";
  rightHeaderText = "";

  constructor(private router: Router, private alertService: AlertService, private headerService: HeaderService) {
    this.router.events
    .pipe (
      filter((event) => event instanceof NavigationStart)
    )
    .subscribe(path => {
      headerService.setHeader('');
      headerService.setRightHeader('');
    });

    this.headerService.headerText.subscribe(headerText => {
      this.headerText = headerText;
    });

    this.headerService.rightHeaderText.subscribe(headerText => {
      this.rightHeaderText = headerText;
    });
  }

  ngOnInit() {
    Parse.initialize('tWrfWCLB3HGsd3bjKawKGgy7tUVGEza94wISYfzr');
    parse.serverURL = 'https://pilotlogparse.herokuapp.com/parse';
  }

  toggleSidebar() {
    this.isSideBarActive = !this.isSideBarActive
  }
}
