import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import FuzzySet from 'fuzzyset'
import * as moment from 'moment';
import * as Parse from 'parse';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-import-logbook',
  templateUrl: './import-logbook.component.html',
  styleUrls: ['./import-logbook.component.css']
})
export class ImportLogbookComponent implements OnInit {

  flightFields = [
    'amelTime', 'totalFlightTime', 'fromAirport', 'dualRecTime',
    'actualInstrumentTime', 'simTime', 'nightTime', 'jetTime', 
    'aselTime', 'amesTime', 'rotorTime', 'tachEnd', 'xCountryTime',
    'soloTime', 'asesTime', 'dualGivenTime', 'numHolds', 'hobbsEnd', 'toAirport',
    'tachStart', 'remarks', 'nightLandings', 'simulatedInstrumentTime', 'hobbsStart',
    'picTime', 'dayLandings', 'aircraftIdent', 'sicTime', 'gliderTime', 'makeModel',
    'numApproaches', 'flightDate', 'IGNORE FIELD'
  ];
  headers = [];
  fuzzy = FuzzySet();
  csvToParseFields = {};
  parseToCsvFields = {};
  csvLines = [];
  importedCount = 0;
  importedMessage = '';

  constructor(private headerService: HeaderService, private alertService: AlertService) {
    headerService.setHeader('Import Logbook');
  }

  ngOnInit() {
    this.flightFields = this.flightFields.sort((a, b) => a.localeCompare(b));
    this.flightFields.forEach(element => {
      this.fuzzy.add(element);
    });
  }

  changeListener(files: FileList){
    if(files && files.length === 1) {
      let file : File = files.item(0); 
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
          let csv: string = reader.result as string;
          csv.split('\n')[0].trim().split(',').forEach(element => {
            this.headers.push(element);
            this.nearestMatch(element);
          });

          this.csvLines = csv.split('\n').slice(1);
      }
     }
  }

  nearestMatch(headerName) {
    if(this.fuzzy.get(headerName) !== null) {
      let nearestMatch = this.fuzzy.get(headerName)[0][1];
      if(!!nearestMatch) {
        this.csvToParseFields[headerName] = nearestMatch;
        this.parseToCsvFields[nearestMatch] = headerName;
      } 
    } else {
      this.csvToParseFields[headerName] = 'IGNORE FIELD';
    }
  }

  import() {
    if(this.csvLines.length === 0) {
      this.alertService.error('No data to import');
      return;
    }

    this.importedCount = 0;
    this.importedMessage = "Importing...";
    this.csvLines.forEach(element => {
      let line = element.trim().split(',');
      let newLine = {};
      this.headers.forEach(header => {
        if(!!this.csvToParseFields[header] && this.csvToParseFields[header] !== 'IGNORE FIELD') {
          newLine[this.csvToParseFields[header]] = line[this.headers.indexOf(header)];
        }
      });
      this.parseSave(newLine);
    });
  }

  parseSave(newLine: {}) {
    const flight = Parse.Object.extend('Flight');
    const newFlight = new flight();
    newFlight.setACL(new Parse.ACL(Parse.User.current()));
    newFlight.set('user', Parse.User.current());
    newFlight.set('flightDate', moment(newLine['flightDate'], 'M/D/YYYY').toDate() || new Date());
    newFlight.set('amelTime', parseFloat(newLine['amelTime']) || 0.0);
    newFlight.set('totalFlightTime',  parseFloat(newLine['totalFlightTime']) || 0.0);
    newFlight.set('fromAirport',  newLine['fromAirport']);
    newFlight.set('dualRecTime', parseFloat(newLine['dualRecTime']) || 0.0);
    newFlight.set('actualInstrumentTime',  parseFloat(newLine['actualInstrumentTime']) || 0.0);
    newFlight.set('simTime',  parseFloat(newLine['simTime'])) || 0.0;
    newFlight.set('nightTime',  parseFloat(newLine['nightTime']) || 0.0);
    newFlight.set('jetTime',  parseFloat(newLine['jetTime']) || 0.0);
    newFlight.set('aselTime',  parseFloat(newLine['aselTime']) || 0.0);
    newFlight.set('amesTime',  parseFloat(newLine['amesTime']) || 0.0);
    newFlight.set('rotorTime',  parseFloat(newLine['rotorTime']) || 0.0);
    newFlight.set('tachEnd', parseFloat(newLine['tachEnd']) || 0.0);
    newFlight.set('xCountryTime',parseFloat(newLine['xCountryTime']) || 0.0);
    newFlight.set('soloTime', parseFloat(newLine['soloTime']) || 0.0);
    newFlight.set('asesTime', parseFloat(newLine['asesTime']) || 0.0);
    newFlight.set('dualGivenTime',parseFloat(newLine['dualGivenTime']) || 0.0);
    newFlight.set('numHolds', parseInt(newLine['numHolds']) || 0);
    newFlight.set('hobbsEnd', parseFloat(newLine['hobbsEnd']) || 0.0);
    newFlight.set('toAirport', newLine['toAirport'] || "");
    newFlight.set('tachStart', parseFloat(newLine['tachStart']) || 0.0);
    newFlight.set('remarks', newLine['remarks'] || "");
    newFlight.set('nightLandings', parseInt(newLine['nightLandings']) || 0);
    newFlight.set('simulatedInstrumentTime', parseFloat(newLine['simulatedInstrumentTime']) || 0.0);
    newFlight.set('hobbsStart',parseFloat(newLine['hobbsStart']) || 0.0);
    newFlight.set('picTime', parseFloat(newLine['picTime']) || 0.0);
    newFlight.set('dayLandings', parseInt(newLine['dayLandings']) || 0);
    newFlight.set('aircraftIdent', newLine['aircraftIdent'] || "");
    newFlight.set('sicTime', parseFloat(newLine['sicTime']) || 0.0);
    newFlight.set('gliderTime', parseFloat(newLine['gliderTime']) || 0.0);
    newFlight.set('makeModel', newLine['makeModel'] || "");
    newFlight.set('numApproaches', parseInt(newLine['numApproaches']) || 0);

    newFlight.save().then(() => {
      this.importedCount++;

      if(this.importedCount === this.csvLines.length) {
        this.importedMessage = "Import Complete";
      }
    }).catch(error => {
      console.log(error);
    });
  }

}
