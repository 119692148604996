import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  success?: string;
  error?: string;

  private successSubscription: Subscription;
  private errorSubscription: Subscription;
  private hideTimer = timer(3000);
  private hideTimerSubscription: Subscription;
  showSuccessAlert = false;
  showErrorAlert = false;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.errorSubscription = this.alertService.errorMessage.subscribe(message => {
      if (message == null || message === '') {
        this.showErrorAlert = false;
        return;
      }

      this.showErrorAlert = true;
      this.error = message;

      if (this.hideTimerSubscription != null) {
        this.hideTimerSubscription.unsubscribe();
      }

      this.hideTimerSubscription = this.hideTimer.subscribe(time => {
        this.showErrorAlert = false;
      });
    });

    this.successSubscription = this.alertService.successMessage.subscribe(message => {
      if (message == null || message === '') {
        this.showSuccessAlert = false;
        return;
      }

      this.showSuccessAlert = true;
      this.success = message;

      if (this.hideTimerSubscription != null) {
        this.hideTimerSubscription.unsubscribe();
      }

      this.hideTimerSubscription = this.hideTimer.subscribe(time => {
        this.showSuccessAlert = false;
      });
    });
  }

  ngOnDestroy() {
    this.successSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();

    if (this.hideTimerSubscription != null) {
      this.hideTimerSubscription.unsubscribe();
    }
  }

}
