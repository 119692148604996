import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import * as Parse from 'parse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css']
})
export class LeftNavComponent implements OnInit {
  constructor(public loginService: LoginService, private router: Router) { }

  ngOnInit() { }

  logOut() {
    Parse.User.logOut();
    this.router.navigate(['/login']);
  }

  getCurrentUser() {
    if (Parse.User.current() != null) {
      return Parse.User.current().getUsername();
    } else {
      return null;
    }
  }
}
