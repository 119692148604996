import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService{
  private headerSubject = new BehaviorSubject('');
  private rightHeaderSubject = new BehaviorSubject('');

  headerText = this.headerSubject.asObservable();
  rightHeaderText = this.rightHeaderSubject.asObservable();

  constructor() { }

  setHeader(header) {
    this.headerSubject.next(header);
  }

  setRightHeader(rightHeader) {
    this.rightHeaderSubject.next(rightHeader);
  }
}
